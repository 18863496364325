import React from 'react';
import styled from 'styled-components';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import ButtonRed from '../components/ButtonRed';

const StyledNotFoundPage = styled.div`
  p {
    font-size: 3em;
    color: black;
    margin-bottom: 1em;
    @media (max-width: ${breakpoints.mobileMax}) {
      font-size: 1em;
    }
  }
`;

const NotFoundPage = () => (
  <StyledNotFoundPage class="page-container center-404">
    <div>
      <h1>404</h1>
      <p>Something went wrong.</p>
      <ButtonRed textLabel="Back to Home" pageLink="/" />
    </div>
  </StyledNotFoundPage>
);

export default NotFoundPage;
